import { useAppRouter } from '@core/hooks/UseAppRouter'
import React, { FC, useEffect } from 'react'
import { useAuthenData } from 'src/store/AuthData'
import { LINK_URL_ADMIN_REVIEW_JOBS, LINK_URL_LOGIN } from 'src/utilities/LinkURL'
import { anonymousAccess } from 'src/utilities/PageUtilities'

const IndexPage: FC = () => {
  const router = useAppRouter()
  const { isLogined } = useAuthenData()

  useEffect(() => {
    router.push(isLogined ? LINK_URL_ADMIN_REVIEW_JOBS : LINK_URL_LOGIN)
  }, [])

  return <></>
}

export default anonymousAccess(IndexPage)
